<template>
  <app-collapse
    :id="`work-form-${index}`"
    type="margin"
  >
    <app-collapse-item
      :is-visible="true"
      :title="`${index}. ${$t('Work situation')}`"
    >
      <b-form-checkbox
        v-model="employer.currently_employer"
        class="mb-1"
        @input="setCurrentlyEmployer"
      >
        {{ `${$t("Currently working for Employer")} ${index}` }}
      </b-form-checkbox>
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <div class="col-md-6 px-0 pr-md-1">
          <b-form-group
            :label="$t('Started working')"
            label-for="Started working"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Started working')"
              rules="required"
            >
              <b-form-datepicker
                v-model="employer.started_working"
                class="bg-light w-auto"
                nav-button-variant="muted"
                placeholder="MM/DD/YYYY"
                :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                :state="errors.length > 0 ? false : null"
                :date-format-options="{
                  year: 'numeric',
                  day: 'numeric',
                  month: 'numeric',
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div
          v-if="!currently_employer"
          class="col-md-6 px-0 pl-md-1"
        >
          <b-form-group
            :label="$t('Resigned')"
            label-for="Resigned"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Resigned')"
              rules="required"
            >
              <b-form-datepicker
                v-model="employer.resigned"
                nav-button-variant="muted"
                class="bg-light w-auto"
                placeholder="MM/DD/YYYY"
                :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                :state="errors.length > 0 ? false : null"
                :date-format-options="{
                  year: 'numeric',
                  day: 'numeric',
                  month: 'numeric',
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>
      <b-form-group
        :label="$t('Position')"
        label-for="Position"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Position')"
          rules="required"
        >
          <v-select
            id="Position"
            v-model="employer.specialty_level_id"
            class="bg-light"
            label="name"
            :placeholder="$t('Position')"
            :reduce="(category) => category.id"
            :options="jobCategories"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('Field of work')"
        label-for="Field of work"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Field of work')"
          rules="required"
        >
          <v-select
            id="Field of work"
            v-model="employer.university_specialty_id"
            class="bg-light"
            label="name"
            :placeholder="$t('Field of work')"
            :reduce="(specialty) => specialty.id"
            :state="errors.length > 0 ? false : null"
            :options="specialties"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <ChooseWorkplaceForm
        :have-initial-data="true"
        :value="employer"
      />
    </app-collapse-item>
  </app-collapse>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BFormGroup,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { min } from '@validations'
import ChooseWorkplaceForm from '@/components/workplace/ChooseWorkplaceForm.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    BFormCheckbox,
    ChooseWorkplaceForm,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: () => ({
        started_working: null,
        resigned: null,
        specialty_level_id: null,
        university_specialty_id: null,
        workplace_situation_group: null,
        workplace_situation_type: null,
        workplace_situation_entity_id: null,
        currently_employer: false,
      }),
    },
  },
  data() {
    return {
      currently_employer: null,
      employer: this.value,
      min,
    }
  },
  computed: {
    jobCategories() {
      return this.translate(
        this.$store.getters['jobCategories/jobCategories'],
        this.$i18n.locale,
      )
    },
    specialties() {
      return this.translate(
        this.$store.getters['specialties/universitySpecialties'],
        this.$i18n.locale,
      )
    },
  },
  watch: {
    employer: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true,
    },
    value(newValue) {
      this.employer = newValue
    },
    // eslint-disable-next-line object-shorthand
    'employer.workplace_situation_group'() {
      this.$emit('resetValidation')
    },

  },
  created() {
    if (this.value.resigned === null) {
      this.employer.currently_employer = true
      this.currently_employer = true
    }
  },
  methods: {
    setCurrentlyEmployer(value) {
      this.currently_employer = value
    },
  },
}
</script>
