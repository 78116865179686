<template>
  <div class="personal-info">
    <span class="personal-info__title">{{ $t('Personal info') }}</span>
    <div class="info">
      <p v-html="user.user_info.bio" ></p>
      <b-container class="info__about-my">
        <b-row v-if="employers.length">
          <b-col
            cols="0.5"
            class=" d-flex justify-content-center align-items-center"
          >
            <img
              :src="location"
              alt="location"
            >
          </b-col>
          <b-col>
            <span>{{ `${$t('Workplace')}:` }} <strong>{{ getWorkplace }}</strong></span>
          </b-col>
        </b-row>
        <b-row v-if="employers.length" >
          <b-col
            cols="0.5"
            class="d-flex justify-content-center align-items-center"
          >
            <img
              :src="graduation"
              alt="location"
            >
          </b-col>
          <b-col>
            <span> {{ `${$t('Position')}:` }} <strong>{{ getPosition }}</strong></span>
          </b-col>
        </b-row>

        <!-- <b-row>
          <b-col
            cols="0.5"
            class=" d-flex justify-content-center align-items-center"
          >
            <img
              :src="home"
              alt="home"
            >
          </b-col>
          <b-col>
            <span>Studied at Yale University</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="0.5"
            class=" d-flex justify-content-center align-items-center"
          >
            <img
              :src="location"
              alt="location"
            >
          </b-col>
          <b-col>
            <span>Studied at Yale University</span>
          </b-col>
        </b-row> -->
      </b-container>

    </div>
  </div>
</template>

<script>
import placeholderAdministrator from '@/assets/images/pages/profile-company/placeholder-administrator.svg'
import location from '@/assets/images/pages/profile-company/location.svg'
import graduation from '@/assets/images/pages/profile-company/graduation.svg'
import home from '@/assets/images/pages/profile-company/home.svg'

import {
  BRow,
  BCol,
  BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      placeholderAdministrator,
      location,
      graduation,
      home,
    }
  },
  computed: {
    employers() {
      return this.user.user_info.user_info_employers
    },
    getPosition() {
      const jobCategories = this.translate(
        this.$store.getters['jobCategories/jobCategories'],
        this.$i18n.locale,
      )
      if (this.employers.length && jobCategories.length) {
        const specialtyId = this.employers[0].specialty_level_id
        return jobCategories.filter(el => el.id === specialtyId)[0].name
      }
      return ''
    },
    getWorkplace() {
      if (this.employers.length) {
        const workplaceSituationType = this.employers[0].workplace_situation_type
        return this.employers[0].workplace_situation[workplaceSituationType !== 'department' ? workplaceSituationType : 'hospital_clinic_department'].name
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.personal-info {
  background: white;
  border-radius: 6px;
  padding: 20px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #5E5873;
  }
}

.info {
  p {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__about-my {
    img {
      width: 16px;
      height: 16px;
    }

    div.row {
      padding-bottom: 10px;
    }
  }
}
</style>
