<template>
  <div>
    <b-form-group class="w-100">
      <b-form-radio-group
        v-model="employer.workplace_situation_group"
        class="w-100 buttons-container ml-n2 ml-md-0"
        button-variant="outline-primary"
        buttons
      >
        <b-form-radio
          v-for="(item, key) in workplaceSituations[0]"
          :key="item.name"
          class="d-flex align-items-center justify-content-center"
          :value="key"
        >
          {{ $t(key) }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <div
      v-if="employer.workplace_situation_group === 'general_practice'"
      id="general_practice"
    >
      <b-form-group
        :label="$t('County')"
        label-for="County"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('County')"
          rules="required"
        >
          <v-select
            id="County"
            v-model="county_id"
            class="bg-light"
            label="name"
            :placeholder="$t('County')"
            :reduce="(county) => county.id"
            :state="errors.length > 0 ? false : null"
            :options="counties"
            @input="changeCounty"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('Municipality')"
        label-for="Municipality"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Municipality')"
          rules="required"
        >
          <v-select
            id="Municipality"
            v-model="city_id"
            class="bg-light"
            label="name"
            :placeholder="$t('Municipality')"
            :options="cities"
            :reduce="(city) => city.id"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :label="$t('Clinic name')"
        label-for="Clinic name"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Clinic name')"
          rules="required"
        >
          <v-select
            id="Clinic name"
            v-model="employer.workplace_situation_entity_id"
            class="bg-light"
            label="name"
            :placeholder="$t('Clinic name')"
            :reduce="(clinic) => clinic.id"
            :state="errors.length > 0 ? false : null"
            :options="clinics"
            @input="employer.workplace_situation_type = 'clinic'"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </div>
    <div
      v-if="employer.workplace_situation_group === 'hospital'"
      id="hospital"
    >
      <b-form-group
        :label="$t('Hospital')"
        label-for="Hospitaldi"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Hospital')"
          rules="required"
        >
          <v-select
            id="Hospitaldi"
            v-model="hospital"
            class="bg-light"
            label="name"
            :placeholder="$t('Hospital')"
            :reduce="(hospital) => hospital.id"
            :state="errors.length > 0 ? false : null"
            :options="hospitals"
            @input="employer.workplace_situation_type = 'hospital'"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        v-if="hospitalClinics.length && !onlyHospital"
        :label="$t('Clinic name')"
        label-for="Clinic name"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Clinic name')"
        >
          <v-select
            id="Clinic name"
            v-model="hospital_clinic"
            class="bg-light"
            label="name"
            :placeholder="$t('Clinic name')"
            :reduce="(clinic) => clinic.id"
            :state="errors.length > 0 ? false : null"
            :options="hospitalClinics"
            @input="employer.workplace_situation_type = 'clinic'"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        v-if="departments.length && !onlyHospital"
        :label="$t('Department')"
        label-for="Department"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Department')"
        >
          <v-select
            id="Department"
            v-model="hospital_clinic_department"
            class="bg-light"
            label="name"
            :placeholder="$t('Department')"
            :options="departments"
            :reduce="(department) => department.id"
            :state="errors.length > 0 ? false : null"
            @input="employer.workplace_situation_type = 'department'"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </div>
    <div
      v-if="employer.workplace_situation_group === 'other'"
      id="other"
    >
      <b-form-group
        :label="$t('Workplace')"
        label-for="Workplace"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Workplace')"
          rules="required|min:2"
        >
          <b-form-input
            id="Workplace"
            v-model="employer.workplace_situation_entity_id"
            class="bg-light"
            :placeholder="$t('Workplace')"
            :state="errors.length > 0 ? false : null"
            @input="employer.workplace_situation_type = 'custom_workplace'"
          />
          <!-- <v-select
              id="Workplace"
              v-model="employer.workplace_situation_entity_id"
              :taggable="true"
              class="bg-light"
              label="name"
              :placeholder="$t('Workplace')"
              :reduce="(workplace) => workplace.id"
              :state="errors.length > 0 ? false : null"
              :options="customWorkplaces"
              :dropdown-should-open="dropdownShouldOpen"
              @option:created="addWorkplace"
            /> -->
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </div>
    <h5 v-if="employer.workplace_situation_group !== 'other'">
      {{ $t("If you do not find your workplace in the list, let us know:") }}
      <a href="mailto:support@norskhelseportal.no">
        support@norskhelseportal.no
      </a>
    </h5>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { min } from '@validations'

export default {
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
  },
  props: {
    haveInitialData: {
      type: Boolean,
      default: false,
    },
    onlyHospital: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({
        started_working: null,
        resigned: null,
        specialty_level_id: null,
        university_specialty_id: null,
        workplace_situation_group: null,
        workplace_situation_type: null,
        workplace_situation_entity_id: null,
        currently_employer: false,
      }),
    },
  },
  data() {
    return {
      county_id: null,
      city_id: null,
      hospital: null,
      hospital_clinic: null,
      hospital_clinic_department: null,
      employer: this.value,
      selectedCategory: 'general_practice',
      min,
    }
  },
  computed: {
    jobCategories() {
      return this.translate(
        this.$store.getters['jobCategories/jobCategories'],
        this.$i18n.locale,
      )
    },
    counties() {
      return this.translate(
        this.$store.getters['filterData/counties'],
        this.$i18n.locale,
      )
    },
    cities() {
      return this.translate(
        this.$store.getters['filterData/citiesByCountyId'],
        this.$i18n.locale,
      )
    },
    clinics() {
      return this.$store.getters['clinics/clinics']
    },
    hospitals() {
      return this.$store.getters['hospitals/hospitals']
    },
    hospitalClinics() {
      return this.$store.getters['clinics/hospitalClinics']
    },
    departments() {
      return this.$store.getters['departments/departments']
    },
    workplaceSituations() {
      return this.$store.getters['workplaceSituations/workplaceSituations']
    },
    specialties() {
      return this.translate(
        this.$store.getters['specialties/universitySpecialties'],
        this.$i18n.locale,
      )
    },
  },
  watch: {
    employer: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true,
    },
    value(newValue) {
      this.employer = newValue
    },
    // eslint-disable-next-line object-shorthand
    'employer.workplace_situation_group'() {
      this.hospital = null
      this.county_id = null
      this.employer.workplace_situation_entity_id = null
      this.employer.workplace_situation_type = null
      this.$emit('resetValidation')
    },
    county_id(value, oldValue) {
      if (value) this.$store.dispatch('filterData/fetchCitiesByCountyId', value)
      else {
        this.$store.commit('filterData/clearCities')
        this.city_id = null
        this.employer.workplace_situation_entity_id = null
      }
      if (oldValue) {
        this.$store.commit('filterData/clearCities')
        this.city_id = null
        this.employer.workplace_situation_entity_id = null
      }
    },
    city_id(value, oldValue) {
      if (value) this.$store.dispatch('clinics/fetchClinicsByCityId', value)
      else {
        this.$store.commit('clinics/clearClinics')
        this.employer.workplace_situation_entity_id = null
      }
      if (oldValue) {
        this.$store.commit('clinics/clearClinics')
        this.employer.workplace_situation_entity_id = null
      }
    },
    hospital(value, oldValue) {
      if (value) this.$store.dispatch('clinics/fetchHospitalClinicsByHospitalId', value)
      else {
        this.$store.commit('clinics/clearHospitalClinics')
        this.hospital_clinic = null
      }
      if (oldValue) {
        this.$store.commit('clinics/clearHospitalClinics')
        this.hospital_clinic = null
      }
      this.employer.workplace_situation_entity_id = value
    },
    hospital_clinic(value, oldValue) {
      this.employer.workplace_situation_entity_id = value
      if (value) {
        this.$store.dispatch(
          'departments/fetchDepartmentByHospitalClinicId',
          value,
        )
      } else {
        this.$store.commit('departments/clearDepartments')
        this.hospital_clinic_department = null
        this.employer.workplace_situation_type = 'hospital'
        this.employer.workplace_situation_entity_id = this.hospital
      }
      if (oldValue) {
        this.$store.commit('departments/clearDepartments')
        this.hospital_clinic_department = null
      }
    },
    hospital_clinic_department(value) {
      if (value) this.employer.workplace_situation_entity_id = value
      else {
        this.employer.workplace_situation_type = this.hospital_clinic ? 'clinic' : 'hospital'
        this.employer.workplace_situation_entity_id = this.hospital_clinic ? this.hospital_clinic : this.hospital
      }
    },
  },
  created() {
    if (this.haveInitialData) {
      if (this.value.workplace_situation_group === 'general_practice') {
        this.county_id = this.value.workplace_situation.county.id
        this.city_id = this.value.workplace_situation.city.id
      } else if (this.value.workplace_situation_group === 'hospital') {
        const keys = Object.entries(this.value.workplace_situation)
        keys.forEach(([key]) => {
          this[key] = this.value.workplace_situation[key].id
        })
      } else if (this.value.workplace_situation_group === 'other') {
        this.employer.workplace_situation_entity_id = this.value.workplace_situation.custom_workplace.name
      }
    }
  },
  methods: {
    changeCounty(val) {
      this.$emit('changeCounty', val)
    },
  },
}
</script>
