var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-collapse',{attrs:{"id":"doctorate-form","type":"margin"}},[_c('app-collapse-item',{attrs:{"is-visible":true,"title":"1. Doctorate"}},[_c('b-form-group',{attrs:{"label":_vm.$t('University'),"label-for":"university-select"}},[_c('validation-provider',{attrs:{"name":_vm.$t('University'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"university-select","label":"name","reduce":function (university) { return university.id; },"options":_vm.universities,"state":errors.length > 0 ? false : null},model:{value:(_vm.doctorateData.university_id),callback:function ($$v) {_vm.$set(_vm.doctorateData, "university_id", $$v)},expression:"doctorateData.university_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-between"},[_c('div',{staticClass:"col-md-6 px-0"},[_c('b-form-group',{attrs:{"label":_vm.$t('Graduated'),"label-for":"dgraduated"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Graduated'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light w-auto",attrs:{"id":"dgraduated","nav-button-variant":"muted","placeholder":"MM/DD/YYYY","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"state":errors.length > 0 ? false : null,"date-format-options":{
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
              }},model:{value:(_vm.doctorateData.graduated),callback:function ($$v) {_vm.$set(_vm.doctorateData, "graduated", $$v)},expression:"doctorateData.graduated"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-form-group',{attrs:{"label":_vm.$t('Specialty'),"label-for":"specialty-select"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Specialty'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"specialty-select","reduce":function (specialty) { return specialty.id; },"state":errors.length > 0 ? false : null,"label":"name","options":_vm.specialties},model:{value:(_vm.doctorateData.specialty_id),callback:function ($$v) {_vm.$set(_vm.doctorateData, "specialty_id", $$v)},expression:"doctorateData.specialty_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }