<template>
  <b-container>
    <div class="d-flex align-items-start flex-column">
      <b-button
        v-if="buttons.changeProfileInformation.isVisible"
        class="mb-1 btn-width"
        @click="handleClickOnChangeBtn('changeProfileInformation')"
      >
        <feather-icon
          icon="UserIcon"
          class="mr-50 icon-color"
        />
        <span class="btn-text-color">
          {{ $t("Profile information") }}
        </span>
      </b-button>
      <form-container
        v-else
        :title="$t('Profile information')"
        @cancel="handleClickOnChangeBtn('changeProfileInformation')"
        @save="
          $refs.profileInformationForm.setProfileInfo(
            'changeProfileInformation'
          )
        "
      >
        <profile-information-form
          ref="profileInformationForm"
          :user-info="user.user_info"
        />
      </form-container>
      <b-button
        v-if="buttons.changeEmail.isVisible"
        class="mb-1 btn-width"
        @click="handleClickOnChangeBtn('changeEmail')"
      >
        <feather-icon
          icon="MailIcon"
          class="mr-50 icon-color"
        />
        <span class="btn-text-color">{{ $t("Change email") }} - </span>
        <span class="btn-text-color font-weight-bold">{{ user.email }}</span>
      </b-button>
      <form-container
        v-else
        :title="$t('Change email')"
        @cancel="handleClickOnChangeBtn('changeEmail')"
        @save="$refs.changeEmailForm.changeEmail('changeEmail')"
      >
        <change-email-form
          ref="changeEmailForm"
          :set-email="user.email"
        />
      </form-container>
      <b-button
        v-if="buttons.changePass.isVisible"
        class="mb-1 btn-width"
        @click="handleClickOnChangeBtn('changePass')"
      >
        <feather-icon
          icon="UnlockIcon"
          class="mr-50 icon-color"
        />
        <span class="btn-text-color">{{ $t("Change password") }}</span>
      </b-button>
      <form-container
        v-else
        :title="$t('Change password')"
        @cancel="handleClickOnChangeBtn('changePass')"
        @save="$refs.changePassworForm.changePassword('changePass')"
      >
        <change-password-form ref="changePassworForm" />
      </form-container>
      <b-button
        v-if="buttons.change2step.isVisible && false"
        class="mb-1 btn-width"
        @click="handleClickOnChangeBtn('change2step')"
      >
        <feather-icon
          icon="ShieldIcon"
          class="mr-50 icon-color"
        />
        <span class="btn-text-color">{{
          $t("Activate 2-step authentication")
        }}</span>
      </b-button>
      <form-container
        v-else-if="false"
        :title="$t('Activate 2-step authentication')"
        @cancel="handleClickOnChangeBtn('change2step')"
        @save="handleClickOnChangeBtn('change2step')"
      >
        <two-step-authentication-form
          ref="twoStepAuthenticationForm"
          :phone-number="+user.user_info.private_mobile"
        />
      </form-container>
      <b-button
        v-if="buttons.changeNotification.isVisible"
        class="btn-width"
        @click="handleClickOnChangeBtn('changeNotification')"
      >
        <feather-icon
          icon="BellIcon"
          class="mr-50 icon-color"
        />
        <span class="btn-text-color">{{ $t("Notifications") }}</span>
      </b-button>
      <form-container
        v-else
        :title="$t('Notification settings')"
        @cancel="handleClickOnChangeBtn('changeNotification')"
        @save="
          $refs.notificationSettingsForm.setNotificationSettings(
            'changeNotification'
          )
        "
      >
        <notification-settings-form
          ref="notificationSettingsForm"
          :user_setting="user.user_setting"
        />
      </form-container>
      <b-button
        class="font-weight-bold mt-1"
        variant="outline-primary"
        @click="showModal('showPageRemoveRequestModal')"
      >
        {{ $t('Request page removal') }}
      </b-button>
      <page-remove-request-modal
        :is-company="false"
        @requestSended="showModal('showPageRemoveRequestSendedModal')"
      />
      <page-remove-request-sended-modal />
    </div>
  </b-container>
</template>

<script>
import {
  BContainer, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import defaultAvatar from '@/assets/images/pages/profile/user-default-avatar.svg'
import defaultBackground from '@/assets/images/pages/profile/user-profile-background.svg'

import FormContainer from '@/components/profile_page/FormContainer.vue'

import ProfileInformationForm from '@/components/profile_page/ProfileInformationForm.vue'
import ChangeEmailForm from '@/components/profile_page/ChangeEmailForm.vue'
import ChangePasswordForm from '@/components/profile_page/ChangePasswordForm.vue'
import TwoStepAuthenticationForm from '@/components/profile_page/TwoStepAuthenticationForm.vue'
import NotificationSettingsForm from '@/components/profile_page/NotificationSettingsForm.vue'
import PageRemoveRequestModal from '@/components/modal/PageRemoveRequest.vue'
import PageRemoveRequestSendedModal from '@/components/modal/PageRemoveRequestSended.vue'

export default {
  name: 'ProfilePage',
  components: {
    BContainer,
    BButton,
    FormContainer,
    ProfileInformationForm,
    ChangeEmailForm,
    ChangePasswordForm,
    TwoStepAuthenticationForm,
    NotificationSettingsForm,
    PageRemoveRequestModal,
    PageRemoveRequestSendedModal,
  },
  data() {
    return {
      defaultAvatar,
      defaultBackground,
      mainProps: {
        width: 100,
        height: 100,
        class: 'm1',
      },
      buttons: {
        changeProfileInformation: {
          isVisible: true,
        },
        changeEmail: {
          isVisible: true,
        },
        changePass: {
          isVisible: true,
        },
        change2step: {
          isVisible: true,
        },
        changeNotification: {
          isVisible: true,
        },
      },
    }
  },
  computed: {
    specialty() {
      return this.$store.getters['specialties/specialty'](
        this.user.user_info.user_info_educations[0].specialty_id,
      ) || ''
    },
    user() {
      return this.$store.getters['auth/user']
    },
  },
  created() {
    EventBus.$on('handleClickOnChangeBtn', this.handleClickOnChangeBtn)
    this.$store.dispatch('filterData/fetchCounties')
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('specialties/fetchUniversitySpecialties')
    this.$store.dispatch('academicLevels/fetchAcademicLevels')
    this.$store.dispatch('jobCategories/fetchJobCategories')
    this.$store.dispatch('universities/fetchUniversities')
    this.$store.dispatch('workplaces/fetchWorkplaces')
    this.$store.dispatch('customWorkplaces/fetchWorkplaces')
    this.$store.dispatch('hospitals/fetchHospitals')
    this.$store.dispatch('workplaceSituations/fetchWorkplaceSituations')
    this.$store.dispatch('hospitals/fetchHospitals')
  },
  methods: {
    showModal(modalName) {
      EventBus.$emit(modalName)
    },
    handleClickOnChangeBtn(eventName) {
      this.$set(
        this.buttons[eventName],
        'isVisible',
        !this.buttons[eventName].isVisible,
      )
    },
    setUserBackgroundImg(event) {
      if (event.target.files[0]) {
        try {
          const file = event.target.files[0]
          this.$store
            .dispatch('auth/changeUserBackgroundImg', file)
            .then(res => {
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Your picture is saved!'),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
              }
            })
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
        }
      }
    },
    setUserAvatar(event) {
      if (event.target.files[0]) {
        try {
          const file = event.target.files[0]
          this.$store.dispatch('auth/changeUserAvatar', file).then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Your picture is saved!'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            }
          })
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_responsive.scss";

.card-img {
  @include media-q-xs;
  @include media-q-sm {
    height: 260px;
  }
}

.user-card {
  &-img {
    width: 100%;
  }
  @include media-q-xs;
  @include media-q-sm {
    display: flex;
    flex-direction: column;
    justify-content: none;
    width: 100%;
    &-img {
      justify-content: none;
      flex-direction: column;
    }
    &-info {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      &-text {
        text-align: center;
      }
    }
    &-button {
      text-align: center;
      width: 100%;
    }
  }
}

input {
  @include media-q-xs;
  @include media-q-sm;
  @include media-q-md {
    max-width: 100%;
  }
}

.card-block {
  @include media-q-xs;
  @include media-q-sm;
  @include media-q-md {
    &-title {
      margin-bottom: 20px;
    }
    &-item {
      min-width: 100%;
      justify-content: center;
    }
    &-checkbox-title {
      @include max-screen(400px) {
        flex-direction: column;
      }
    }
    &-checkbox-btn {
      @include max-screen(400px) {
        margin-left: 60px !important;
      }
    }
  }
}

.btn-width {
  @include max-screen(400px) {
    width: 100%;
  }
}

.card-img {
  @include max-screen(576px) {
    height: 320px;
  }
}

.change-pic {
  width: 100px;
  background: black;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 69%;
  visibility: hidden;
}
.mr-1:hover {
  .change-pic {
    visibility: visible;
    cursor: pointer;
  }
}
.img-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0) 20.46%, #000000 160%);
}
.img-border {
  border: 2px solid #2986e8;
}
.icon-color {
  color: #2986e8;
}
.btn-text-color {
  color: #000000;
}
</style>
