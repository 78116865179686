var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"w-100"},[_c('b-form-radio-group',{staticClass:"w-100 buttons-container ml-n2 ml-md-0",attrs:{"button-variant":"outline-primary","buttons":""},model:{value:(_vm.employer.workplace_situation_group),callback:function ($$v) {_vm.$set(_vm.employer, "workplace_situation_group", $$v)},expression:"employer.workplace_situation_group"}},_vm._l((_vm.workplaceSituations[0]),function(item,key){return _c('b-form-radio',{key:item.name,staticClass:"d-flex align-items-center justify-content-center",attrs:{"value":key}},[_vm._v(" "+_vm._s(_vm.$t(key))+" ")])}),1)],1),(_vm.employer.workplace_situation_group === 'general_practice')?_c('div',{attrs:{"id":"general_practice"}},[_c('b-form-group',{attrs:{"label":_vm.$t('County'),"label-for":"County"}},[_c('validation-provider',{attrs:{"name":_vm.$t('County'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"County","label":"name","placeholder":_vm.$t('County'),"reduce":function (county) { return county.id; },"state":errors.length > 0 ? false : null,"options":_vm.counties},on:{"input":_vm.changeCounty},model:{value:(_vm.county_id),callback:function ($$v) {_vm.county_id=$$v},expression:"county_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3856377337)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Municipality'),"label-for":"Municipality"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Municipality'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Municipality","label":"name","placeholder":_vm.$t('Municipality'),"options":_vm.cities,"reduce":function (city) { return city.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.city_id),callback:function ($$v) {_vm.city_id=$$v},expression:"city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,683910776)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Clinic name'),"label-for":"Clinic name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Clinic name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Clinic name","label":"name","placeholder":_vm.$t('Clinic name'),"reduce":function (clinic) { return clinic.id; },"state":errors.length > 0 ? false : null,"options":_vm.clinics},on:{"input":function($event){_vm.employer.workplace_situation_type = 'clinic'}},model:{value:(_vm.employer.workplace_situation_entity_id),callback:function ($$v) {_vm.$set(_vm.employer, "workplace_situation_entity_id", $$v)},expression:"employer.workplace_situation_entity_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1955851351)})],1)],1):_vm._e(),(_vm.employer.workplace_situation_group === 'hospital')?_c('div',{attrs:{"id":"hospital"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Hospital'),"label-for":"Hospitaldi"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Hospital'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Hospitaldi","label":"name","placeholder":_vm.$t('Hospital'),"reduce":function (hospital) { return hospital.id; },"state":errors.length > 0 ? false : null,"options":_vm.hospitals},on:{"input":function($event){_vm.employer.workplace_situation_type = 'hospital'}},model:{value:(_vm.hospital),callback:function ($$v) {_vm.hospital=$$v},expression:"hospital"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2328683796)})],1),(_vm.hospitalClinics.length && !_vm.onlyHospital)?_c('b-form-group',{attrs:{"label":_vm.$t('Clinic name'),"label-for":"Clinic name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Clinic name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Clinic name","label":"name","placeholder":_vm.$t('Clinic name'),"reduce":function (clinic) { return clinic.id; },"state":errors.length > 0 ? false : null,"options":_vm.hospitalClinics},on:{"input":function($event){_vm.employer.workplace_situation_type = 'clinic'}},model:{value:(_vm.hospital_clinic),callback:function ($$v) {_vm.hospital_clinic=$$v},expression:"hospital_clinic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,201768688)})],1):_vm._e(),(_vm.departments.length && !_vm.onlyHospital)?_c('b-form-group',{attrs:{"label":_vm.$t('Department'),"label-for":"Department"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Department')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Department","label":"name","placeholder":_vm.$t('Department'),"options":_vm.departments,"reduce":function (department) { return department.id; },"state":errors.length > 0 ? false : null},on:{"input":function($event){_vm.employer.workplace_situation_type = 'department'}},model:{value:(_vm.hospital_clinic_department),callback:function ($$v) {_vm.hospital_clinic_department=$$v},expression:"hospital_clinic_department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1456533023)})],1):_vm._e()],1):_vm._e(),(_vm.employer.workplace_situation_group === 'other')?_c('div',{attrs:{"id":"other"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Workplace'),"label-for":"Workplace"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Workplace'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"Workplace","placeholder":_vm.$t('Workplace'),"state":errors.length > 0 ? false : null},on:{"input":function($event){_vm.employer.workplace_situation_type = 'custom_workplace'}},model:{value:(_vm.employer.workplace_situation_entity_id),callback:function ($$v) {_vm.$set(_vm.employer, "workplace_situation_entity_id", $$v)},expression:"employer.workplace_situation_entity_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,483873477)})],1)],1):_vm._e(),(_vm.employer.workplace_situation_group !== 'other')?_c('h5',[_vm._v(" "+_vm._s(_vm.$t("If you do not find your workplace in the list, let us know:"))+" "),_c('a',{attrs:{"href":"mailto:support@norskhelseportal.no"}},[_vm._v(" support@norskhelseportal.no ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }