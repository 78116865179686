<template>
  <div class="col card-block-item">
    <validation-provider
      #default="{ errors }"
      :name="$t('Phone number')"
      rules="required|phone"
    >
      <label for="phone-input">{{ $t("Phone number") }}</label>
      <cleave
        id="phone-input"
        v-model="currentPhone"
        class="form-control bg-light"
        :raw="false"
        :options="options.phone"
        :placeholder="$t('Enter your phone number')"
        :state="errors.length > 0 ? false : null"
      />
      <small class="text-danger d-block">{{ errors[0] }}</small>
    </validation-provider>
    <label for="password-phone-input">{{ $t("Password") }}</label>
    <b-form-input
      id="password-phone-input"
      v-model="phonePassword"
      type="text"
      :placeholder="$t('Verification code')"
      class="col-md-11 mb-1"
    />
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { phone } from '@validations'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.no'

export default {
  components: {
    BFormInput,
    ValidationProvider,
    Cleave,
  },
  props: {
    // eslint-disable-next-line
    phoneNumber: {
      type: Number,
    },
  },
  data() {
    return {
      currentPhone: '',
      phonePassword: '',
      phone,
      options: {
        phone: {
          blocks: [11],
        },
      },
    }
  },
  created() {
    this.currentPhone = this.phoneNumber
  },
}
</script>
