<template>
  <b-container v-if="user">
    <div
      class="w-100 h-25 position-relative profile-bg-img"
      :class="{'change-profile-bg-img': activeTab === 1}"
    >
      <img
        :src="getCoverImagePath"
        class="w-100"
        alt="Background image"
      >
      <div class="position-absolute w-100 align-items-end justify-content-end p-1">
        <span class="change-bg-image-btn">
          {{ $t('Change image') }}
          <input
            class="bg-file-input"
            type="file"
            @change="changeProfileBG"
          >
        </span>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center profile-page-header">
      <div class="d-flex align-items-center">
        <div :class="{ 'position-relative': true, 'change-image': activeTab === 1}">
          <img
            :src="getImagePath"
            rounded="circle"
            alt="profile avatar"
            class="info-profile-my__logo object-fit-cover"
          >
          <div class="position-absolute rounded-circle change-img-bg align-items-center justify-content-center">
            <img
              :src="cameraImage"
              rounded="circle"
              alt="change avatar"
            >
            <input
              class="avatar-file-input"
              type="file"
              @change="changeProfileAvatar"
            >
          </div>
        </div>
        <div>
          <h1 class="info-profile-my__title mt-2 mt-md-1 ml-1">
            {{ user.name }}
          </h1>
          <h5 class="hpr-label mb-0 ml-1 d-flex flex-column d-lg-block">
            <span class="text-dark font-weight-normal">
              {{
                `${$t('Specialty')} - ${getSpecialty}`
              }}
            </span>
            <span v-if="user.approved_hrp === 2">
              <img
                :src="HPRImage"
                alt="HPR verified"
                class="hpr-image ml-0  ml-lg-1"
              >
              {{ $t('HPR verified') }}
            </span>
          </h5>
        </div>
      </div>
      <div
        v-if="activeTab === 0"
        class="follow-profile-my mt-1 mt-md-0"
      >
        <span
          class="follow-profile-my__title"
          :class="{'cursor-pointer': isOwner}"
          @click="showFollowersListModal"
        >
          {{ user.followers_count }} {{ $t('followers') }}
        </span>
        <img
          :src="verticalSmallLine"
          alt="line"
          class="follow-profile-my__vertical-line"
        >
        <b-button
          v-if="isOwner"
          variant="outline-primary"
          @click="activeTab = 1"
        >
          Edit profile
        </b-button>
        <template v-else>
          <b-button
            variant="primary"
            @click="follow"
          >
            {{ user.amIFollowing ? 'Unfollow' : 'Follow' }}
          </b-button>
        </template>
      </div>
    </div>
    <div class="content-profile-my pt-1">
      <!-- Navbar -->
      <div
        class="content-profile-my__navbar"
        :class="{'w-100': isFullScreenContent}"
      >
        <!-- Content -->
        <b-container class="content-profile-my__info nopadding">
          <component
            :is="getComponentInfo"
            @changeTab="changeTab"
          />
        </b-container>
      </div>

      <!-- Right content -->
      <div
        v-if="!isFullScreenContent"
        class="content-profile-my__sidebar"
      >
        <home-sidebar v-if="activeTab === 0" :user="user" />
      </div>
    </div>
    <followers-list-modal :isCompany="false" />
  </b-container>
</template>

<script>

import verticalSmallLine from '@/assets/images/pages/profile-company/vertical-small-line.svg'
import logoPlaceholder from '@/assets/images/pages/profile-company/logo-placeholder.svg'
import cameraImage from '@/assets/images/pages/profile/camera.svg'
import HPRImage from '@/assets/images/pages/profile/HPR.svg'
import coverPlaceholder from '@/assets/images/pages/profile-company/cover-placeholder.svg'
import FollowersListModal from '@/components/profile-company/FollowersListModal/FollowersListModal.vue'

import {
  BContainer,
  BCard,
  BButton,
} from 'bootstrap-vue'

// Content components
import Home from '@/components/profile-my/Home'
import ProfileInformation from '@/components/profile-my/ProfileInformation'

// Sidebar components
import HomeSidebar from '@/components/profile-my/sidebar/Home/Home.vue'

import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ProfilePage',
  components: {
    BContainer,
    BCard,
    BButton,
    Home,
    HomeSidebar,
    ProfileInformation,
    FollowersListModal,
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.slug': {
      async handler() {
        this.fetchUser()
      },
      deep: true,
    },
  },
  data() {
    return {
      verticalSmallLine,
      logoPlaceholder,
      coverPlaceholder,
      cameraImage,
      HPRImage,

      activeTab: 0,
      user: null,
    }
  },
  computed: {
    ...mapGetters({
      currentBreakPoint: 'app/currentBreakPoint',
      authUser: 'auth/user',
    }),
    getCoverImagePath() {
      return this.user.profile_background ? this.user.profile_background : this.coverPlaceholder
    },
    getImagePath() {
      return this.user.profile_avatar ? this.user.profile_avatar : this.logoPlaceholder
    },
    getCoverImgHeight() {
      const sizes = {
        default: '300',
        xs: '150',
        sm: '150',
      }

      return sizes[this.currentBreakPoint] ?? sizes.default
    },
    getComponentInfo() {
      const indexTabs = {
        0: 'Home',
        1: 'ProfileInformation',
      }

      return indexTabs[this.activeTab]
    },
    getSidebarComponent() {
      const indexTabs = {
        0: 'HomeSidebar',
      }

      return indexTabs[this.activeTab]
    },
    isFullScreenContent() {
      return [1].includes(this.activeTab)
    },
    getSpecialty() {
      if (this.user.specialty) {
        return this.user.specialty.name[this.$i18n.locale]
      }

      return ''
    },
    isApprovedHpr() {
      return this.user.user_info.approved_hrp === 2
    },
    isOwner() {
      return this.authUser.slug === this.user.slug
    },
  },
  created() {
    this.fetchUser()
    this.$store.dispatch('jobCategories/fetchJobCategories')
  },
  methods: {
    showFollowersListModal() {
      if (this.isOwner) {
        EventBus.$emit(`showFollowersModalList-${false}`)
      }
    },
    changeTab(tabIndex) {
      this.activeTab = tabIndex
    },
    changeProfileAvatar(event) {
      this.$store.dispatch('auth/changeUserAvatar', event.target.files[0]).then(() => {
        this.fetchUser()
      })
    },
    changeProfileBG(event) {
      this.$store.dispatch('auth/changeUserBackgroundImg', event.target.files[0]).then(() => {
        this.fetchUser()
      })
    },
    async fetchUser() {
      try {
        const res = await axiosIns.get(`profile/${this.$route.params.slug}`)

        if (res.status === 200) {
          this.user = res.data.data
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async follow() {
      try {
        const res = await axiosIns.post(`user/follow/${this.$route.params.slug}`)

        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
          this.fetchUser()
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_responsive.scss";

.change-image:hover {
    .change-img-bg {
      visibility: visible;
      opacity: 1;
    }
  }
.avatar-file-input {
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}
.change-img-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;
  img {
    width: 1.7rem;
    height: 1.7rem;
  }
}

.hpr-label{
  color: #28C76F;
  .hpr-image {
    height: 1.1rem;
    width: 1.1rem;
  }
}

@mixin title($fontSize) {
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize;
    line-height: 24px;
    color: #5E5873;
  }

  .header-profile-my {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    top: 279px;
    position: absolute;
    padding-right: 1.2rem;

    @include media-q-md {
      top: 135px;
    }
    @include max-screen(400px) {
      top: 145px;
    }
  }

  .info-profile-my {
    display: flex;
    align-items: center;

    &__title {
      @include title(30px);

      @include media-q-md {
        font-size: 25px;
      }
      @include media-q-sm {
        font-size: 20px;
      }
    }

    .hpr-label {
      padding-left: 20px;
      margin-top: 8px;

      @include media-q-md {
        padding-left: 5px;
        margin-top: 5px;
      }
      @include media-q-sm {
        padding-left: 10px;
      }
    }

    &__logo {
      width: 110px;
      height: 110px;
      border-radius: 50%;

      @include media-q-md {
        width: 80px;
        height: 80px;
      }
      @include max-screen(400px) {
        width: 50px;
        height: 50px;
      }
    }

    &__specialty {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #0F2F50;
    }

    &__hpr {
      padding-left: 20px;
      display: flex;
      align-items: center;
      span {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #28C76F;
        padding-left: 5px;
      }
    }
  }

  .follow-profile-my {
    &__title {
      @include title(16px);

      @include media-q-md {
        font-size: 14px;
      }
      @include media-q-sm {
        font-size: 12px;
      }
    }

    &__vertical-line {
      padding-left: 30px;
      padding-right: 20px;

      @include media-q-md {
        padding-left: 15px;
        padding-right: 10px;
      }
    }

    button {
      @include media-q-md {
        padding: 8px 15px;
      }
      @include max-screen(400px) {
        padding: 5px 5px;
      }
    }
  }

  .content-profile-my {
    display: flex;
    width: 100%;

    &__navbar {
      width: 70%;
      @include media-q-md {
        width: 100%;
      }
    }

    &__sidebar {
      width: 30%;
      margin-left: 20px;
      height: fit-content;
      @include media-q-md {
        width: 100%;
        margin-left: 0px;
        padding-bottom: 25px;
      }
    }

    @include media-q-md {
      padding-top: 55px;
      flex-direction: column-reverse;
    }
    @include max-screen(400px) {
      padding-top: 30px;
    }
  }

  .info-profile-my-details {
    display: flex;
    padding-top: 5px;
  }

  .info-profile {
    padding-left: 15px;
  }

</style>
