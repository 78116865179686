<template>
  <div class="col d-flex align-items-center card-block-item">
    <div class="mb-0 ml-0">
      <div class="d-flex align-items-center card-block-checkbox-title">
        <span class="col-10 btn-text-color">{{
          $t("SMS webinar reminder")
        }}</span>
        <b-form-checkbox
          v-model="notificationSettings.smsWebinarReminder"
          class="col ml-1 card-block-checkbox-btn"
          switch
        />
      </div>
      <div class="mt-1 d-flex align-items-center card-block-checkbox-title">
        <div class="col-10 btn-text-color">
          {{ $t("Email webinar reminder") }}
        </div>
        <b-form-checkbox
          v-model="notificationSettings.emailWebinarReminder"
          class="col ml-1 card-block-checkbox-btn"
          switch
        />
      </div>
      <div class="mt-1 d-flex align-items-center card-block-checkbox-title">
        <span class="col-10 btn-text-color">{{
          $t("Important updates on email and SMS")
        }}</span>
        <b-form-checkbox
          v-model="notificationSettings.importantUpdatesOnEmailAndSms"
          class="col ml-1 card-block-checkbox-btn"
          switch
        />
      </div>
      <div class="mt-1 d-flex align-items-center card-block-checkbox-title">
        <div class="col-10">
          <span
            class="btn-text-color"
          >{{ $t("New content is published") }}<br></span>
          <p>
            <small>({{ $t("courses, articles or job postings") }})</small>
          </p>
        </div>
        <b-form-checkbox
          v-model="notificationSettings.newContentIsPublished"
          class="col ml-1 mb-1 card-block-checkbox-btn"
          switch
          style="margin-top: -10%"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    // eslint-disable-next-line
    user_setting: {
      type: Object,
    },
  },
  data() {
    return {
      notificationSettings: {
        smsWebinarReminder: true,
        emailWebinarReminder: false,
        importantUpdatesOnEmailAndSms: true,
        newContentIsPublished: false,
      },
    }
  },
  created() {
    this.notificationSettings.smsWebinarReminder = Boolean(
      this.user_setting.sms_webinar_reminder,
    )
    this.notificationSettings.emailWebinarReminder = Boolean(
      this.user_setting.email_webinar_reminder,
    )
    this.notificationSettings.importantUpdatesOnEmailAndSms = Boolean(
      this.user_setting.important_updates_on_email_and_sms,
    )
    this.notificationSettings.newContentIsPublished = Boolean(
      this.user_setting.new_content_is_published,
    )
  },
  methods: {
    async setNotificationSettings(eventName) {
      try {
        const data = {
          sms_webinar_reminder: this.notificationSettings.smsWebinarReminder,
          email_webinar_reminder: this.notificationSettings
            .emailWebinarReminder,
          important_updates_on_email_and_sms: this.notificationSettings
            .importantUpdatesOnEmailAndSms,
          new_content_is_published: this.notificationSettings
            .newContentIsPublished,
        }
        await this.$store.dispatch('auth/changeUserNotificationSettings', data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Your settings is saved!'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
      }
      EventBus.$emit('handleClickOnChangeBtn', eventName)
    },
  },
}
</script>
