<template>
  <b-card
    class="w-100 card-item"
  >
    <div class="row card-block">
      <div class="col-md-2 d-flex align-items-center card-block-item justify-content-center">
        <span class="btn-text-color card-block-title">
          {{ title }}
        </span>
      </div>
      <slot />
      <div
        class="
          col-md-3
          d-flex
          align-items-center
          justify-content-center
          card-block-item
        "
      >
        <b-button
          variant="primary"
          @click="$emit('save')"
        >
          {{ $t("Save") }}
        </b-button>
        <b-button
          class="ml-1"
          variant="outline-primary"
          @click="$emit('cancel')"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
  },
}
</script>
