<template>
  <div class="col card-block-item">
    <validation-provider
      #default="{ errors }"
      name="password"
      rules="required|min:8"
    >
      <label for="old-pass-input">{{ $t("Old password") }}</label>
      <b-form-input
        id="old-pass-input"
        v-model="currentPassword"
        type="password"
        :placeholder="$t('Enter your old password')"
        class="mb-1 col-md-11"
      />
      <small class="text-danger d-block">{{ errors[0] }}</small>
    </validation-provider>
    <validation-provider
      #default="{ errors }"
      name="new_password"
      rules="required|min:8"
    >
      <label for="new-pass-input">{{ $t("New Password") }}</label>
      <b-form-input
        id="new-pass-input"
        v-model="newPassword"
        type="password"
        :placeholder="$t('Enter your new password')"
        class="col-md-11 mb-1"
      />
      <small class="text-danger d-block">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    ValidationProvider,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
    }
  },
  methods: {
    changePassword(eventName) {
      if (this.currentPassword !== this.newPassword) {
        try {
          const data = {
            old_password: this.currentPassword,
            password: this.newPassword,
            password_confirmation: this.newPassword,
          }
          this.$store.dispatch('auth/changeUserPassword', data)
            .then(res => {
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Your new password is saved!'),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                }, { timeout: 5000 })
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('You should to log in with new password!'),
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                }, { timeout: 6000 })
                this.$router.push({ name: 'home' })
                this.$store.dispatch('auth/logout')
              }
            })
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
        }
      }
      EventBus.$emit('handleClickOnChangeBtn', eventName)
    },
  },
}
</script>
