<template>
  <app-collapse
    :id="`doctorate-form`"
    type="margin"
  >
    <app-collapse-item
      :is-visible="true"
      :title="`1. Doctorate`"
    >
      <b-form-group
        :label="$t('University')"
        label-for="university-select"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('University')"
          rules="required"
        >
          <v-select
            id="university-select"
            v-model="doctorateData.university_id"
            class="bg-light"
            label="name"
            :reduce="(university) => university.id"
            :options="universities"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <div class="col-md-6 px-0">
          <b-form-group
            :label="$t('Graduated')"
            label-for="dgraduated"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Graduated')"
              rules="required"
            >
              <b-form-datepicker
                id="dgraduated"
                v-model="doctorateData.graduated"
                nav-button-variant="muted"
                class="bg-light w-auto"
                placeholder="MM/DD/YYYY"
                :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                :state="errors.length > 0 ? false : null"
                :date-format-options="{
                  year: 'numeric',
                  day: 'numeric',
                  month: 'numeric',
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>
      <b-form-group
        :label="$t('Specialty')"
        label-for="specialty-select"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Specialty')"
          rules="required"
        >
          <v-select
            id="specialty-select"
            v-model="doctorateData.specialty_id"
            class="bg-light"
            :reduce="(specialty) => specialty.id"
            :state="errors.length > 0 ? false : null"
            label="name"
            :options="specialties"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </app-collapse-item>
  </app-collapse>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: () => ({
        university_id: null,
        graduated: null,
        specialty_id: null,
      }),
    },
  },
  data() {
    return {
      doctorateData: this.value,
    }
  },
  computed: {
    specialties() {
      return this.translate(
        this.$store.getters['specialties/specialties'],
        this.$i18n.locale,
      )
    },
    universities() {
      return this.translate(
        this.$store.getters['universities/universities'],
        this.$i18n.locale,
      )
    },
  },
  watch: {
    doctorateData: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true,
    },
    value(newValue) {
      this.doctorateData = newValue
    },
  },
}
</script>
