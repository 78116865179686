<template>
  <div class="col card-block-item">
    <validation-provider
      #default="{ errors }"
      name="email"
      rules="required|email"
    >
      <label for="email-input">{{ $t("Email") }}</label>
      <b-form-input
        id="email-input"
        v-model="currentEmail"
        type="email"
        :placeholder="$t('Enter your email')"
        class="col-md-11 mb-1"
      />
      <small class="text-danger d-block">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email } from '@validations'

export default {
  components: {
    BFormInput,
    ValidationProvider,
  },
  props: {
    setEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentEmail: null,
      email,
    }
  },
  created() {
    this.currentEmail = this.setEmail
  },
  methods: {
    changeEmail(eventName) {
      if (this.currentEmail !== this.setEmail) {
        this.$store.dispatch('auth/changeUserEmail', { email: this.currentEmail }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('You should to confirm your new email!'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
      }
      EventBus.$emit('handleClickOnChangeBtn', eventName)
    },
  },
}
</script>
