var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-collapse',{attrs:{"id":("work-form-" + _vm.index),"type":"margin"}},[_c('app-collapse-item',{attrs:{"is-visible":true,"title":(_vm.index + ". " + (_vm.$t('Work situation')))}},[_c('b-form-checkbox',{staticClass:"mb-1",on:{"input":_vm.setCurrentlyEmployer},model:{value:(_vm.employer.currently_employer),callback:function ($$v) {_vm.$set(_vm.employer, "currently_employer", $$v)},expression:"employer.currently_employer"}},[_vm._v(" "+_vm._s(((_vm.$t("Currently working for Employer")) + " " + _vm.index))+" ")]),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-between"},[_c('div',{staticClass:"col-md-6 px-0 pr-md-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Started working'),"label-for":"Started working"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Started working'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light w-auto",attrs:{"nav-button-variant":"muted","placeholder":"MM/DD/YYYY","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"state":errors.length > 0 ? false : null,"date-format-options":{
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
              }},model:{value:(_vm.employer.started_working),callback:function ($$v) {_vm.$set(_vm.employer, "started_working", $$v)},expression:"employer.started_working"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.currently_employer)?_c('div',{staticClass:"col-md-6 px-0 pl-md-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Resigned'),"label-for":"Resigned"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Resigned'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light w-auto",attrs:{"nav-button-variant":"muted","placeholder":"MM/DD/YYYY","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"state":errors.length > 0 ? false : null,"date-format-options":{
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
              }},model:{value:(_vm.employer.resigned),callback:function ($$v) {_vm.$set(_vm.employer, "resigned", $$v)},expression:"employer.resigned"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3625438521)})],1)],1):_vm._e()]),_c('b-form-group',{attrs:{"label":_vm.$t('Position'),"label-for":"Position"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Position'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Position","label":"name","placeholder":_vm.$t('Position'),"reduce":function (category) { return category.id; },"options":_vm.jobCategories,"state":errors.length > 0 ? false : null},model:{value:(_vm.employer.specialty_level_id),callback:function ($$v) {_vm.$set(_vm.employer, "specialty_level_id", $$v)},expression:"employer.specialty_level_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Field of work'),"label-for":"Field of work"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Field of work'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"Field of work","label":"name","placeholder":_vm.$t('Field of work'),"reduce":function (specialty) { return specialty.id; },"state":errors.length > 0 ? false : null,"options":_vm.specialties},model:{value:(_vm.employer.university_specialty_id),callback:function ($$v) {_vm.$set(_vm.employer, "university_specialty_id", $$v)},expression:"employer.university_specialty_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('ChooseWorkplaceForm',{attrs:{"have-initial-data":true,"value":_vm.employer}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }