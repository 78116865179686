<template>
  <b-modal
    :id="`modal-followers-list-${$route.params.slug}`"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3 class="w-100 mx-auto text-center font-weight-bold">
        {{ $t('Followers') }}
      </h3>
    </template>
    <div class="flex-col text-center align-items-center w-100 mb-1">
      <b-input-group class="input-group-merge mb-1">
        <b-input-group-prepend
          class="no-border"
          is-text
        >
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input
          v-model="search"
          type="search"
          class="no-border"
          :placeholder="$t('Search...')"
        />
      </b-input-group>
      <div class="d-flex flex-md-row flex-column followers-list">
        <div class="border-right-light w-md-50 w-100">
          <follower-item
            v-for="follower in followers.firstPart"
            :key="follower.slug"
            :follower="follower"
            :is-company="isCompany"
            @close-modal="closeModal"
          />
        </div>
        <div class="flex-col w-md-50 w-100">
          <follower-item
            v-for="follower in followers.secondPart"
            :key="follower.slug"
            :follower="follower"
            :is-company="isCompany"
            @close-modal="closeModal"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'vue-debounce'

import FollowerItem from './FollowerItem.vue'

export default {
  components: {
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    FollowerItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isCompany: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    followers() {
      const followers = this.$store.getters[this.isCompany ? 'profileCompany/followers' : 'publicProfile/followers']
      const arrayMiddleIndex = Math.ceil(followers.length / 2)
      return {
        firstPart: followers.splice(0, arrayMiddleIndex),
        secondPart: followers,
      }
    },
    search: {
      get() {
        return this.$store.getters[this.isCompany ? 'profileCompany/search' : 'publicProfile/searchFollowers']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit(this.isCompany ? 'profileCompany/setSearch' : 'publicProfile/setSearchFollowers', search)
      }, 500),
    },
  },
  watch: {
    search: {
      handler() {
        this.fetchFollowers()
      },
    },
  },
  created() {
    EventBus.$on(`showFollowersModalList-${this.isCompany}`, () => {
      this.$root.$emit('bv::show::modal', `modal-followers-list-${this.$route.params.slug}`, '#focusThisOnClose')
      this.fetchFollowers()
    })
  },
  methods: {
    fetchFollowers() {
      this.$store.dispatch(this.isCompany ? 'profileCompany/fetchFollowers' : 'publicProfile/fetchFollowers', this.$route.params.slug)
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', `modal-followers-list-${this.$route.params.slug}`, '#focusThisOnClose')
    },
  },
}
</script>
