<template>
  <div class="col px-0 card-block-item">
    <validation-observer ref="userInfoForm">
      <div class="row mb-1">
        <div class="col-2 col-md-1 px-0" />
        <div v-if="userInfo" class="col-8 px-0">
          <h5 class="font-weight-bolder">
            {{ $t("BIO") }}
          </h5>
          <b-form-group
            :label="$t('Description')"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Description')"
            >
              <TinyMCE
                id="tinymce-editor2"
                :menubar="false"
                :toolbar1="false"
                :toolbar2="true"
                v-model="bio"
                :placeholder="$t('Place some text there...')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-2 col-md-3" />
      </div>
      <div class="row">
        <div class="col-2 col-md-1 px-0" />
        <div class="col-8 px-0">
          <h5 class="font-weight-bolder">
            {{ $t("Location") }}
          </h5>
          <b-form-group
            :label="$t('County')"
            label-for="country-select"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('County')"
            >
              <v-select
                id="country-select"
                v-model="county"
                class="bg-light"
                :reduce="(county) => county.id"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="counties"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-2 col-md-3" />
      </div>
      <div
        v-if="!currently_studding"
        class="row align-items-center"
      >
        <div class="w-100 d-flex align-items-center">
          <div class="col-2 col-md-1 px-0 pl-2 h5" />
          <div class="col-8 px-0">
            <div
              class="
                  d-flex
                  flex-md-row flex-column
                  w-100
                  align-items-center
                  justify-content-between
                "
            >
              <div
                class="d-flex w-100 align-items-center justify-content-between mt-2"
              >
                <h5 class="font-weight-bolder mb-0">
                  {{ $t("Professional experience") }}
                </h5>
                <feather-icon
                  icon="PlusCircleIcon"
                  class="text-primary cursor-pointer"
                  size="25rem"
                  @click="addEmployer"
                />
              </div>
            </div>
          </div>
        </div>
        <transition-group
          class="w-100"
          name="fade"
        >
          <div
            v-for="(item, index) in employers"
            :key="index"
            class="w-100 d-flex align-items-center"
          >
            <div class="col-2 col-md-1 px-0 pl-2 h5" />
            <div class="col-8 px-0">
              <work-form
                v-model="employers[index]"
                class="w-100"
                :index="index + 1"
                @resetValidation="$refs.userInfoForm.reset()"
              /></div>
            <div class="col-2 col-md-1 px-0 pl-2 h5">
              <feather-icon
                icon="MinusCircleIcon"
                class="text-primary cursor-pointer"
                size="25rem"
                @click="removeEmployer(index)"
              />
            </div>
          </div>
        </transition-group>
      </div>
      <div class="row">
        <div class="col-2 col-md-1 px-0" />
        <div class="col-8 px-0">
          <h5 class="font-weight-bolder mt-3">
            {{ $t("Education") }}
          </h5>
          <b-form-checkbox
            v-model="currently_studding"
            class="my-1"
          >
            {{ $t("Currently studding") }}
          </b-form-checkbox>
          <b-form-group
            :label="$t('University')"
            label-for="university-select"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('University')"
              rules="required"
            >
              <v-select
                id="university-select"
                v-model="university_id"
                class="bg-light"
                label="name"
                :reduce="(university) => university.id"
                :state="errors.length > 0 ? false : null"
                :options="universities"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-flex flex-md-row flex-column justify-content-between">
            <div class="col-md-6 px-0">
              <b-form-group
                v-if="!currently_studding"
                :label="$t('Graduated')"
                label-for="graduated"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Graduated')"
                  rules="required"
                >
                  <b-form-datepicker
                    id="graduated"
                    v-model="graduated"
                    nav-button-variant="muted"
                    class="bg-light w-auto"
                    placeholder="DD/MM/YYYY"
                    :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                    :state="errors.length > 0 ? false : null"
                    :date-format-options="{
                      year: 'numeric',
                      day: 'numeric',
                      month: 'numeric',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <b-form-group
            :label="$t('Specialty')"
            label-for="specialty-select"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Specialty')"
            >
              <v-select
                id="specialty-select"
                v-model="specialty_id"
                class="bg-light"
                :reduce="(specialty) => specialty.id"
                :state="errors.length > 0 ? false : null"
                label="name"
                :options="specialties"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-2 col-md-3" />
      </div>
      <div
        v-if="!currently_studding"
        class="row align-items-center"
      >
        <div class="w-100 d-flex align-items-center">
          <div class="col-2 col-md-1 px-0 pl-2 h5" />
          <div class="col-8 px-0">
            <div
              class="
                  d-flex
                  flex-md-row flex-column
                  w-100
                  align-items-center
                  justify-content-between
                "
            >
              <div
                class="d-flex w-100 align-items-center justify-content-between mt-2"
              >
                <h5 class="font-weight-bolder mb-0">
                  {{ $t("Add Doctorate") }}
                </h5>
                <feather-icon
                  icon="PlusCircleIcon"
                  class="text-primary cursor-pointer"
                  size="25rem"
                  @click="doctorate = true"
                />
              </div>
            </div>
          </div>
        </div>
        <transition
          class="w-100"
          name="fade"
        >
          <div
            v-if="doctorate"
            class="w-100 d-flex align-items-center"
          >
            <div class="col-2 col-md-1 px-0 pl-2 h5" />
            <div class="col-8 px-0">
              <doctorate-form
                v-model="doctorateData"
                class="w-100"
              /></div>
            <div class="col-2 col-md-1 px-0 pl-2 h5">
              <feather-icon
                icon="MinusCircleIcon"
                class="text-primary cursor-pointer"
                size="25rem"
                @click="doctorate = false"
              />
            </div>
          </div>
        </transition>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { BFormGroup, BFormDatepicker, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TinyMCE from '@/components/editor/TinyMCE.vue'
import DoctorateForm from './profile-information-form/DoctorateForm.vue'
import WorkForm from './profile-information-form/WorkForm.vue'

export default {
  components: {
    BFormGroup,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    DoctorateForm,
    WorkForm,
    TinyMCE,
  },
  data() {
    return {
      county: null,
      currently_studding: null,
      doctorate: false,
      bio: '',
      work: 0,
      currentEmloyer: null,
      university_id: null,
      specialty_id: null,
      graduated: null,
      doctorateData: {
        university_id: null,
        graduated: null,
        specialty_id: null,
      },
      employers: [
      ],
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    counties() {
      return this.translate(
        this.$store.getters['filterData/counties'],
        this.lang,
      )
    },
    specialties() {
      return this.translate(
        this.$store.getters['specialties/specialties'],
        this.lang,
      )
    },
    universities() {
      return this.translate(
        this.$store.getters['universities/universities'],
        this.lang,
      )
    },
    workplaces() {
      return this.translate(
        this.$store.getters['workplaces/workplaces'],
        this.lang,
      )
    },
    jobCategories() {
      return this.translate(
        this.$store.getters['jobCategories/jobCategories'],
        this.lang,
      )
    },
    userInfo() {
      return this.$store.getters['auth/user'].user_info
    },
    customWorkplaces() {
      return this.$store.getters['customWorkplaces/workplaces']
    },
  },
  created() {
    this.addUserData()
  },
  methods: {
    setProfileInfo(eventName) {
      this.$refs.userInfoForm.validate().then(async success => {
        if (success) {
          const data = {
            bio: this.bio,
            county_id: this.county,
            currently_studding: this.currently_studding,
            universities: [
              {
                university_id: this.university_id,
                graduated: this.graduated,
                specialty_id: this.specialty_id,
              },
            ],
          }
          if (this.doctorate && !this.currently_studding) data.universities.push(this.doctorateData)
          if (!this.currently_studding) {
            // Reduction employer objects to the desired form
            const employers = await Promise.all(this.employers.map(async el => {
              const employer = { ...el }
              delete employer.workplace_situation
              // Delete resignet field if current employer
              if (employer.currently_employer) {
                delete employer.resigned
              }
              // Check for adding a new custom workplace
              if (employer.workplace_situation_type === 'custom_workplace') {
                // Check for existing workplace
                const isDublicate = this.customWorkplaces.find(workplace => workplace.name === employer.workplace_situation_entity_id)
                if (isDublicate) {
                  // Workplace allready exists set id to employer object
                  employer.workplace_situation_entity_id = isDublicate.id
                } else {
                  // Workplace are new, create it and set id to employer object
                  employer.workplace_situation_entity_id = await this.addWorkplace(employer.workplace_situation_entity_id)
                }
              }
              return employer
            }))
            data.employers = employers
          }
          this.$store.dispatch('auth/changeProfileInfo', data).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Information changed successfuly!'),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          this.addUserData()
          EventBus.$emit('handleClickOnChangeBtn', eventName)
        }
      })
    },
    addEmployer() {
      this.employers.push({
        started_working: null,
        resigned: null,
        specialty_level_id: null,
        university_specialty_id: null,
        workplace_situation_type: 'general_practice',
        workplace_situation_entity_id: null,
        currently_employer: false,
      })
    },
    removeEmployer(index) {
      this.employers.splice(index, 1)
    },
    addUserData() {
      // eslint-disable-next-line
      if (this.userInfo.user_info_educations[1]) {
        // eslint-disable-next-line
        this.doctorateData = this.userInfo.user_info_educations[1]
        this.doctorateData.university_id = +this.doctorateData.university_id
        this.doctorate = true
      }
      this.currently_studding = Boolean(this.userInfo.currently_studding)
      this.county = this.userInfo.county_id
      this.university_id = +this.userInfo.user_info_educations[0]?.university.id || null
      this.specialty_id = this.userInfo.user_info_educations[0]?.specialty_id || null
      this.graduated = this.userInfo.user_info_educations[0]?.graduated || null
      if (!this.currently_studding) {
        this.employers = this.userInfo.user_info_employers
      }
      this.bio = this.userInfo.bio
    },
    async addWorkplace(workplace) {
      const newWorkplace = await this.$store.dispatch(
        'customWorkplaces/createWorkplace',
        { name: workplace },
      )
      this.$store.dispatch('customWorkplaces/fetchWorkplaces')
      return newWorkplace.id
    },
  },
}
</script>
