<template>
  <div class="profile-company-home">
    <div
      v-if="isOwner"
      class="search-posts"
    >
      <img
        :src="require('@/assets/images/pages/profile/user-default-avatar.svg')"
        alt="User logo"
        width="34"
        height="34"
      >
      <button @click="() => openModal(null)">
        Share your thoughts here...
      </button>
    </div>

    <Post
      v-for="item in posts"
      :key="item.id"
      :item="item"
      @edit-post="openModal"
      @fetch-posts="fetchPosts"
      @update-post="updatePostInfo"
    />

    <div
      class="d-flex align-items-center justify-content-center"
    >
      <b-pagination
        v-if="postsPagination && (postsPagination.total > postsPagination.per_page)"
        v-model="pageNumber"
        :per-page="postsPagination.per_page"
        :total-rows="postsPagination.total"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>

    <PostManage
      v-if="showModal"
      :post="post"
      entity="user-posts"
      @hide="showModal = false"
      @add-post="addPost"
      @update-post="updatePostInfo"
    />
  </div>
</template>

<script>
import Post from '@/components/profile-company/Post.vue'
import { BPagination } from 'bootstrap-vue'
import PostManage from '@/components/modal/post-manage'
import axiosIns from '@/libs/axios'

export default {
  name: 'Home',
  components: {
    Post,
    BPagination,
    PostManage,
  },
  data() {
    return {
      showModal: false,
      post: null,

      posts: [],
      postsPagination: null,
    }
  },
  computed: {
    isOwner() {
      const user = this.$store.getters['auth/user']
      return user.slug === this.$route.params.slug
    },
    pageNumber: {
      get() { return this.postsPagination.current_page },
      set(pageNumber) { this.fetchPosts(pageNumber) },
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.slug': {
      async handler() {
        this.fetchPosts()
      },
      deep: true,
    },
  },
  created() {
    this.fetchPosts()
  },
  methods: {
    openModal(post) {
      this.post = post
      this.showModal = true
    },
    async addPost(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        this.posts.unshift(post)
      })
    },
    async fetchPosts(pageNumber = 1) {
      try {
        const { data: res } = await axiosIns.get(`/user-posts/${this.$route.params.slug}?page=${pageNumber}`)

        if (res.data && res.meta) {
          this.posts = res.data
          this.postsPagination = res.meta.pagination
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async updatePostInfo(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        const array = [...this.posts]
        const index = array.findIndex(el => el.slug === slug)
        array[index] = post
        this.posts = [...array]
      })
    },
  },
}
</script>
