<template>
  <div class="e-sidebar-home">
    <PersonaInfo :user="user" />
  </div>
</template>

<script>
import PersonaInfo from '@/components/profile-my/sidebar/PersonaInfo.vue'

export default {
  name: 'Home',
  components: {
    PersonaInfo,
  },
  props: {
    user: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.e-sidebar-home {
  .administrator {
    margin-top: 20px;
  }
}
</style>
