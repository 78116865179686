<template>
  <div
    class="w-100 d-flex mb-1 px-1 mr-0 follower-item"
    @click="goToProfile"
  >
    <b-avatar
      class="mr-1"
      size="44"
      variant="primary"
      :src="follower.profile_avatar ? follower.profile_avatar : require('@/assets/images/pages/profile/user-default-avatar.svg')"
    />
    <div class="d-flex flex-column justify-content-center text-left px-0">
      <h5 class="font-weight-bold mb-0">
        {{ follower.name }}
      </h5>
      <h6
        v-if="follower.user_info && follower.user_info.address"
        class="mb-0 text-muted"
      >
        {{ follower.user_info.address }}
      </h6>
    </div>
  </div>
</template>
<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    follower: {
      type: Object,
      default: () => {},
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToProfile() {
      if (this.isCompany) return
      this.$emit('close-modal')
      this.$router.push(`/profile/${this.follower.slug}`)
    },
  },
}
</script>
